<template>
      <div class="login">
          <div v-if="!$auth.isAuthenticated">
            <v-row align="center" justify="center">
            <v-card>
            <v-col >
              <h1>Welcome</h1>
              <h2>to the Boulder Portal</h2>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn color="success" v-on:click="login">Click Here to Login</v-btn>
            </v-col>
              <v-img
              src= "https://static.wixstatic.com/media/1af86c_9e8d3b5cf7c84cdc9094307c5959ebda.png/v1/fill/w_312,h_127,al_c,q_85,usm_0.66_1.00_0.01/1af86c_9e8d3b5cf7c84cdc9094307c5959ebda.webp"
              max-height="200"
              max-width="400"
            />
            </v-card>
            </v-row>
          </div>
          <div v-if="$auth.isAuthenticated">
              <v-card align="center" justify="center">
                  <h1>Welcome</h1>
                  <h2>to the Boulder Portal</h2>
              </v-card>
              <v-card class="success" align="center" justify="center">
                  <div>
                  <h1>Agents and staff, please remember to select a Homeowner from the dropdown menu at the top left.</h1>
                  </div>
              </v-card>
              <v-card class="success" align="center" justify="center">
                  <div>
                  <h1>Homeowners, please remember to select a Home Address from the dropdown menu at the top left.</h1>
                  </div>
              </v-card>
          </div>
      </div>
</template>

<script>
// @ is an alias to /src
    import axios from 'axios'
    import {getInstance} from "../auth/index"
    import jwt_decode from "jwt-decode";
export default {
  name: "Login",
  mounted: function () {
    console.log("🚀 🚀 Mounted. Welcome!")
    this.getData()
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    getData: async function () {
      const instance = getInstance();
      console.log("Watching loaded.. 👓")
      instance.$watch("loading", async loading => {
        if (!loading) {
          if (instance.isAuthenticated) {
            let token = await instance.getTokenSilently();

            // THIS SETS THE AUTH HEADER TO EVERY AXIOS CALL
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            const header = jwt_decode(`Bearer ${token}`)
            localStorage.removeItem('homeid');
            localStorage.setItem('permissions', header.permissions)
            if(localStorage.getItem('permissions') === 'read:agent,write:agent'){
              localStorage.setItem('agentEmail', this.$auth.user.email)
            }
            if(localStorage.getItem('permissions') === 'read:homeowner'){
              localStorage.setItem('homeownerEmail', this.$auth.user.email)
            }
            if(localStorage.getItem('permissions') === 'read:homeowner'){
              localStorage.setItem('homeownerEmail', this.$auth.user.email)
            }
            if( window.localStorage ){
              if(!localStorage.getItem('firstReLoad')){
               localStorage['firstReLoad'] = true;
               window.location.reload();
              } else {
               localStorage.removeItem('firstReLoad');
              }
            }
          }
        }
      })
      },
  }
};
</script>

