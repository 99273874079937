import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login.vue'
import { authGuard } from "../auth/authGuard";

const Index = () => import('@/views/Index')
// const NavIndex = () => import('@/views/NavIndex')
const login = () => import('@/views/Login')
const Profile = () => import('@/views/Profile')
const role = localStorage.getItem('permissions')

Vue.use(Router)

export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: configRoutes()
})

function configRoutes() {
    return [
        {
            path: '/',
            name: Login,
            redirect: '/login',
            component: Index,
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: login,
                },
                {
                    path: "/profile",
                    name: "profile",
                    component: Profile,
                    beforeEnter: authGuard,
                },
                {
                    name: 'Staff Dashboard',
                    path: 'staff/dashboard',
                    component: function () {
                        if(localStorage.getItem('permissions') === 'create:users,fullaccess:staff') {
                            return  import('@/views/boulder_staff/StaffDashboard')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'New Home Owners',
                    path: 'staff/new-user',
                    component: () => import('@/views/boulder_staff/NewUser'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Vendor Emails',
                    path: 'staff/vendor-emails',
                    component: () => import('@/views/boulder_staff/VendorEmails'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Change Orders',
                    path: 'staff/change-orders',
                    component: () => import('@/views/boulder_staff/ChangeOrders'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'NORTH METRO SUPPLIES, INC.',
                    path: 'staff/staff-forms',
                    component: () => import('@/views/boulder_staff/StaffForms'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Staff Meeting Summary',
                    path: 'staff/staff-meeting-notes',
                    component: () => import('@/views/boulder_staff/StaffMeetingNotes'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Staff Home Section Prices',
                    path: 'staff/home-section-prices',
                    component: () => import('@/views/boulder_staff/HomeSelectionPrices'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Update Cost Breakdown',
                    path: 'staff/staff-update-breakdown',
                    component: () => import('@/views/boulder_staff/StaffUpdateBreakdown'),
                    beforeEnter: authGuard,
                },

                //Agent Section
                {
                    name: 'Agent Dashboard',
                    path: 'agent/agent-dashboard',
                    component: () => import('@/views/boulder_agent/AgentDashboard'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Agent Uploads',
                    path: 'agent/uploads',
                    component: () => import('@/views/boulder_agent/Uploads'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Agent Change Orders',
                    path: 'agent/change-orders',
                    component: () => import('@/views/boulder_agent/ChangeOrders'),
                    beforeEnter: authGuard,
                },

                //Homwowner Section
                {
                    name: 'Home Owner Dashboard',
                    path: 'homeowner/dashboard',
                    component: () => import('@/views/boulder_homeowner/HomeOwnerDashboard'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Updates',
                    path: 'homeowner/home-updates',
                    component: () => import('@/views/boulder_homeowner/HomePhase'),
                    beforeEnter: authGuard,
                },
                // {
                //     name: 'Purchase Agreement',
                //     path: 'homeowner/purchase-agreement',
                //     component: () => import('@/views/boulder_homeowner/PurchaseAgreement'),
                //     beforeEnter: authGuard,
                // },
                {
                    name: 'Home Selections',
                    path: 'homeowner/home-selections',
                    component: () => import('@/views/boulder_homeowner/HomeSelections'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Vendor Selection',
                    path: 'homeowner/vendor-selection',
                    component: () => import('@/views/boulder_homeowner/VendorSelection'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Change Order',
                    path: 'homeowner/change-order',
                    component: () => import('@/views/boulder_homeowner/ChangeOrder'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Design',
                    path: 'homeowner/home-design',
                    component: () => import('@/views/boulder_homeowner/HomeDesign'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Uploads',
                    path: 'homeowner/uploads',
                    component: () => import('@/views/boulder_homeowner/Uploads'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Cost Breakdown Page 1',
                    path: 'homeowner/cost-breakdown-page-one',
                    component: () => import('@/views/boulder_homeowner/CostBreakdownPageOne'),
                    beforeEnter: authGuard,
                },
                {
                    name: 'Cost Breakdown Page 2',
                    path: 'homeowner/cost-breakdown-page-two',
                    component: () => import('@/views/boulder_homeowner/CostBreakdownPageTwo'),
                    beforeEnter: authGuard,
                },

                //Home Selections
                {
                    name: 'Home Selections - Section 1',
                    path: 'home_selections/section-1',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/Section1')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySection1')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section 2',
                    path: 'home_selections/section-2',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/Section2')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySection2')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section 3',
                    path: 'home_selections/section-3',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/Section3')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySection3')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section 4',
                    path: 'home_selections/section-4',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/Section4')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySection4')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section A',
                    path: 'home_selections/section-a',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/SectionA')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySectionA')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section B',
                    path: 'home_selections/section-b',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/SectionB')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySectionB')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section C',
                    path: 'home_selections/section-c',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/SectionC')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySectionC')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section D',
                    path: 'home_selections/section-d',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/SectionD')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySectionD')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section F',
                    path: 'home_selections/section-f',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/SectionF')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySectionF')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section 5',
                    path: 'home_selections/section-5',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/Section5')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySection5')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section 6',
                    path: 'home_selections/section-6',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/Section6')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySection6')
                        }
                    },
                    beforeEnter: authGuard,
                },
                                {
                    name: 'Home Selections - Section 7',
                    path: 'home_selections/section-7',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/Section7')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySection7')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section 8',
                    path: 'home_selections/section-8',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/Section8')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySection8')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section I',
                    path: 'home_selections/section-i',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/SectionI')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySectionI')
                        }
                    },
                    beforeEnter: authGuard,
                },
                {
                    name: 'Home Selections - Section J',
                    path: 'home_selections/section-j',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/home_selections/SectionJ')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/home_selections/ReadOnlySectionJ')
                        }
                    },
                    beforeEnter: authGuard,
                },
                //Vendor Selections
                {
                    name: 'Vendor Selections - Vendor Appointments',
                    path: 'vendor_selections/vendor-appointments',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff'|| role === 'read:agent,write:agent' || role === 'read:homeowner') {
                            return import('@/views/vendor_selections/VendorAppointments')
                        }
                        else if(!role) {
                            return import('@/views/vendor_selections/ReadOnlyVendorAppointments')
                        }
                    },
                    beforeEnter: authGuard,
                },
                // {
                //     name: 'Vendor Selections - Ultimate Cabinetry',
                //     path: 'vendor_selections/ultimate-cabinetry',
                //     component: function () {
                //         if(role === 'create:users,fullaccess:staff'|| role === 'read:agent,write:agent') {
                //             return import('@/views/vendor_selections/UltimateCabinetry')
                //         }
                //         else if(role === 'read:homeowner') {
                //             return import('@/views/vendor_selections/ReadOnlyUltimateCabinetry')
                //         }
                //     },
                //     beforeEnter: authGuard,
                // },
                // {
                //     name: 'Vendor Selections - Superior Flooring',
                //     path: 'vendor_selections/superior-flooring',
                //     component: function () {
                //         if(role === 'create:users,fullaccess:staff'|| role === 'read:agent,write:agent') {
                //             return import('@/views/vendor_selections/SuperiorFlooring')
                //         }
                //         else if(role === 'read:homeowner') {
                //             return import('@/views/vendor_selections/ReadOnlySuperiorFlooring')
                //         }
                //     },
                //     beforeEnter: authGuard,
                // },
                // {
                //     name: 'Vendor Selections - Street Lighting',
                //     path: 'vendor_selections/street-lighting',
                //     component: function () {
                //         if(role === 'create:users,fullaccess:staff'|| role === 'read:agent,write:agent') {
                //             return import('@/views/vendor_selections/StreetLighting')
                //         }
                //         else if(role === 'read:homeowner') {
                //             return import('@/views/vendor_selections/ReadOnlyStreetLighting')
                //         }
                //     },
                //     beforeEnter: authGuard,
                // },
                // {
                //     name: 'Vendor Selections - Condor Fireplace',
                //     path: 'vendor_selections/condor-fireplace',
                //     component: function () {
                //         if(role === 'create:users,fullaccess:staff'|| role === 'read:agent,write:agent') {
                //             return import('@/views/vendor_selections/CondorFireplace')
                //         }
                //         else if(role === 'read:homeowner') {
                //             return import('@/views/vendor_selections/ReadOnlyCondorFireplace')
                //         }
                //     },
                //     beforeEnter: authGuard,
                // },
                {
                    name: 'Vendor Selections - Allowance Summary',
                    path: 'vendor_selections/allowance-summary',
                    component: function () {
                        if(role === 'create:users,fullaccess:staff' || role === 'read:agent,write:agent') {
                            return import('@/views/vendor_selections/AllowanceSummary')
                        }
                        else if(role === 'read:homeowner') {
                            return import('@/views/vendor_selections/ReadOnlyAllowanceSummary')
                        }
                    },
                    beforeEnter: authGuard,
                },
            ]
        },
    ]
}