import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
    primary: '#4CAF50',
    // primary: '#005a9e',
    secondary: '#9C27b0',
    accent: '#9C27b0',
    info: '#00CAE3',
}

export default new Vuetify({
  theme: {
    themes: {
      // dark: theme,
      light: theme,
    },
  },
})
